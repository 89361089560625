export const calculateSalesMonthsInWarehouse = (params, includeReception, selectedSale, expectedGrowth, temporality) => {
    const { stockTotal, totalPendingCustomerOrders, totalPendingProviderOrders } = params
    if (includeReception) {
        return Math.round(((parseInt(stockTotal) + parseInt(totalPendingProviderOrders) - parseInt(totalPendingCustomerOrders)) / (selectedSale*(1+expectedGrowth/100)*(1+temporality/100))) * 10) / 10
      }
      else {
        return Math.round(((stockTotal - totalPendingCustomerOrders) / (selectedSale*(1+expectedGrowth/100)*(1+temporality/100))) * 10) / 10
      }
    }

