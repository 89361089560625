import { Link, useLocation } from "react-router-dom";
import { Routes } from "../config/Routes";
import { mdiLogoutVariant, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";
import { useState } from "react";
import { AppBar, Drawer, IconButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";


const Menu: React.FC = () => {
  const history = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const route = history.pathname;

  const handleLogout = () => {
    localStorage.removeItem("token");
    return store.dispatch({ type: AuthActions.DelToken, payload: null });
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className="flex justify-between">
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setIsNavOpen(true)}>
            <Icon path={mdiMenu} size={1} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" PaperProps={{ sx: { backgroundColor: "#BF549A", color: "white" } }} open={isNavOpen} onClose={() => setIsNavOpen(false)}>
        <div className="flex flex-col space-y-4 p-4">

          <Link to={Routes.Home}>

            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === Routes.Home ? "text-pineBlack" : ""
                }`}
            >
              Inicio
            </div>
          </Link>
          <Link to={Routes.Uploads}>
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === Routes.Uploads ? "text-pineBlack" : ""
                }`}
            >
              Cargas Masivas
            </div>
          </Link>
          <Link to={Routes.Warehouse}>
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === Routes.Warehouse ? "text-pineBlack" : ""
                }`}
            >
              Bodega Arturo Prat
            </div>
          </Link>
          <Link to={Routes.Glossary}>
            <div
              onClick={() => setIsNavOpen(false)}
              className={`${route === Routes.Glossary ? "text-pineBlack" : ""
                }`}
            >
              Glosario
            </div>
          </Link>


          <div onClick={handleLogout} style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemText>Cerrar sesión</ListItemText>
            <ListItemIcon sx={{ color: "inherit", minWidth: '0', marginRight: '8px' }}>
              <Icon className="w-7" path={mdiLogoutVariant}></Icon>
            </ListItemIcon>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Menu;
