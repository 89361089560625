import axios from "./client"

const API_URL = process.env.REACT_APP_API_URL;

export const uploadMassiveProductFile = async (products: ProductInExcel) => {
    try {
        await axios.post(`${API_URL}products/massive`, {
            products
        })
        return true
    }
      catch(err) {
        console.log(err)
        return false 
      }
}

export const uploadMassivePackingFile = async (products: PackingInExcel) => {
    try {
        await axios.post(`${API_URL}products/packing`, {
            products
        })
        return true
    }
      catch(err) {
        console.log(err)
        return false 
      }
}

export const getSkusWithSales = async () => {
    try {
        const response = await axios.get(`${API_URL}products/sales`)
        return response.data.data.sales as ProductWithSalesAndInfo[]
    }
    catch(e) {
        console.log(e)
        return []
    }
}

export const updateStock = async () => {
    try {
        await axios.get(`${API_URL}products/updateStock`)
        return true
    }
    catch(e) {
        alert("Error actualizando stock")
        console.log(e)
        return false
    }
}