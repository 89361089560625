import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import { getLimits, updateLimits } from "../../../services/limitService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
}

const LimitModal: React.FC<Props> = ({ open, onClose }) => {
    const [bottomLimit, setBottomLimit] = useState<number>(0);
    const [topLimit, setTopLimit] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        if (open) {
            getLimits().then((limits) => {
                if (limits) {
                    const topLimit = limits.find((limit: Limit) => limit.typeOfLimit === "top")?.limit!;
                    const bottomLimit = limits.find((limit: Limit) => limit.typeOfLimit === "bottom")?.limit!;
                    setTopLimit(topLimit);
                    setBottomLimit(bottomLimit);
                }
            });
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Definir Limites
            </DialogTitle>
            <DialogContent>
                <div className="flex gap-2 mt-2">
                    <TextField
                        label="Limite Inferior (%)"
                        type="number"
                        value={bottomLimit}
                        onChange={(e) => setBottomLimit(parseInt(e.target.value))}
                    />
                    <TextField
                        label="Limite Superior (%)"
                        type="number"
                        value={topLimit}
                        onChange={(e) => setTopLimit(parseInt(e.target.value))}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                    disabled={loading}
                    variant="contained"
                    onClick={
                        async () => {
                            setLoading(true);
                            await updateLimits([
                                {
                                    typeOfLimit: "top",
                                    limit: topLimit
                                },
                                {
                                    typeOfLimit: "bottom",
                                    limit: bottomLimit
                                }
                            ]).then((success) => {
                                if (success) {
                                    alert("Limites actualizados");
                                    onClose();
                                }
                            });
                            setLoading(false);
                        }
                    }>Guardar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default LimitModal;