import Excel from "exceljs";

export async function getProductsToArray(file) {
    const reader = new FileReader();
    const wb = new Excel.Workbook();
    reader.readAsArrayBuffer(file);
    return new Promise((resolve, reject) => {
            reader.onload = () => {
              const buffer = reader.result;
              let rowsToArray = [];
              let headers = [];
              wb.xlsx
                .load(buffer)
                .then((workbook) => {
                  workbook.worksheets[0].eachRow((row, rowNumber) => {
                    if (rowNumber === 1) {
                      headers = row.values;
                      headers = headers.map((header) => {
                          if (header === "CODIGO") {
                            return "sku";
                          } else if (header === "DESCRIPCION") {
                            return "description";
                          } else if (header === "MARCA") {
                            return "brand";
                          } else if (header === "FAMILIA") {
                            return "family";
                          } else if (header === "PROVEEDOR") {
                            return "provider";
                          } else if (header === "EN BODEGA") {
                            return "isInWarehouse";
                          } else if (header === "COSTO") {
                            return "cost";
                          }  else if(header === "PRODUCTO NUEVO") {
                            return "new"
                          } else if(header === "TEMPORALIDAD") {
                            return "seasonality"
                          } else {
                            console.log(header);
                            reject("Error en el formato del encabezado");
                          }
                          // eslint-disable-next-line array-callback-return
                          return; // no deberia ocurrir nunca 
                      });
                    }
                    if (rowNumber > 1) {
                      let rowToArray = {};
                      for (let i = 0; i < headers.length; i++) {
                        if (headers[i] === "sku") {
                          rowToArray[headers[i]] = row.values[i].toString();
                        } else if (
                          headers[i] === "description" ||
                          headers[i] === "brand" ||
                          headers[i] === "family" ||
                          headers[i] === "provider"
                        ) {
                          rowToArray[headers[i]] = row.getCell(i).text;
                        } else if ( headers[i] === "isInWarehouse" ) {
                            const warehouseValue = row.values[i].toString().toLowerCase()
                            if(warehouseValue !== "si" && 
                                warehouseValue !== "no") {
                                    reject("Error en la fila" + rowNumber)
                                }
                                else {
                                    if(warehouseValue === "si") {
                                        rowToArray[headers[i]] = true
                                    }
                                    if(warehouseValue === "no") {
                                        rowToArray[headers[i]] = false
                                    }
                                }
                        } else if(headers[i] === "new") {
                          if(row.getCell(i).text === "N") {
                            rowToArray[headers[i]] = true
                          } 
                          else {
                            rowToArray[headers[i]] = false
                          }
                        } else if(headers[i] === "seasonality") {
                          if(["TT","V","I"].includes(row.values[i].toString())) {
                            rowToArray[headers[i]] = row.values[i].toString()
                          }
                          else {
                            reject("Error con la temporalidad en la fila" + rowNumber)
                          }
                        }
                        else {
                          if (row.values[i] && typeof row.values[i] !== "number") {  // para los costos 
                            reject(
                              "Error en el formato de los datos en la fila " + rowNumber
                            );
                          } else {
                            rowToArray[headers[i]] = row.values[i];
                          }
                        }
                      }
                      delete rowToArray.undefined
                      rowsToArray.push(rowToArray);

                    }
                });
            })
            .then(() => {
              resolve(rowsToArray);
            });
        };
      });
   
}