import axios from "./client"

const API_URL = process.env.REACT_APP_API_URL;

export const uploadMassiveProviderFile = async (providers: Provider[]) => {
    try {
        await axios.post(`${API_URL}providers`, {
            providers
        })
        return true
    }
        catch (err) {
            console.log(err)
            return false
    }
}