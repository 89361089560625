import axios from "axios";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";
import client from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (username: string, password: string) => {
  try {
    const {
      data: {
        data: { token },
      },
    } = await axios.post<{ data: { token: string } }>(`${API_URL}login`, {
      username,
      password,
    });
    store.dispatch({ type: AuthActions.SetToken, payload: token });
    localStorage.setItem("token", token);
  } catch (error) {
    console.log(error);
}
};

export const initLogin = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      await client.get(`${API_URL}validate`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      store.dispatch({ type: AuthActions.SetToken, payload: token });
    } catch (error) {
      localStorage.removeItem("token");
      store.dispatch({ type: AuthActions.SetToken, payload: null });
      
    }
  }
};
