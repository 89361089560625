import axios from "./client"

const API_URL = process.env.REACT_APP_API_URL;

export const uploadMassiveProviderOrderFile = async (providerOrders: ProviderOrder[]) => {
    try {
        await axios.post(`${API_URL}providerOrders`, {
            providerOrders
        })
        return true
    }
      catch(err) {
        console.log(err)
        return false 
      }
}