import { Alert, Button } from "@mui/material"
import { getProductsToArray } from "../../../utils/readProducts"
import { uploadMassivePackingFile, uploadMassiveProductFile } from "../../../services/productService"
import { useState } from "react"
import { getCustomerOrdersToArray } from "../../../utils/readCustomerOrders"
import { uploadMassiveCustomerOrderFile } from "../../../services/customerOrderService"
import { getProviderOrdersToArray } from "../../../utils/readProviderOrders"
import { uploadMassiveProviderOrderFile } from "../../../services/providerOrderService"
import { getProvidersToArray } from "../../../utils/readProviders"
import { uploadMassiveProviderFile } from "../../../services/providerService"
import { getPackingToArray } from "../../../utils/readPacking"

const UploadsView = () => {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState("")

    const closeAlert = () => {
        setTimeout(() => {
            setSuccess(false);
            setError("");
        }, 2000); // 2000 milisegundos = 2 segundos
    };
    const handleProviderOrderUpload = (e: any) => {
        getProviderOrdersToArray(e.target.files[0]).then(
            (providerOrders) => {
                uploadMassiveProviderOrderFile(providerOrders).then(
                    (res) => {
                        if (res) {
                            setSuccess(true)
                            closeAlert()
                        }
                        else {
                            setError("Error en servidor")
                            closeAlert()
                        }
                    }
                )
            }
        ).catch(
            (e) => {
                setError(e)
                closeAlert()
            }
        ).finally(
            () => e.target.value = ""
        )
    }
    const handleProvidersUpload = (e: any) => {
        getProvidersToArray(e.target.files[0]).then(
            (providers) => {
                uploadMassiveProviderFile(providers).then(
                    (res) => {
                        if (res) {
                            setSuccess(true)
                            closeAlert()
                        }
                        else {
                            setError("Error en servidor")
                            closeAlert()
                        }
                    }
                )
            }
        ).catch(
            (e) => {
                setError(e)
                closeAlert()
            }
        ).finally(
            () => e.target.value = ""
        )
    }
    const handleProductsUpload = (e: any) => {
        getProductsToArray(e.target.files[0]).then(
            (products) => {
                uploadMassiveProductFile(products).then(
                    (res) => {
                        if (res) {
                            setSuccess(true)
                            closeAlert()
                        }
                        else {
                            setError("Error en servidor")
                            closeAlert()
                        }
                    }
                )
            }
        ).catch((e) => {
            setError(e)
            closeAlert()
        }).finally(
            () => e.target.value = ""
        )
    }
    const handleCustomerOrderUpload = (e: any) => {
        getCustomerOrdersToArray(e.target.files[0]).then(
            (customerOrders) => {
                uploadMassiveCustomerOrderFile(customerOrders).then(
                    (res) => {
                        if (res) {
                            setSuccess(true)
                            closeAlert()
                        }
                        else {
                            setError("Error en servidor")
                            closeAlert()
                        }
                    }
                )
            }
        ).catch(
            (e) => {
                setError(e)
                closeAlert()
            }
        ).finally(
            () => e.target.value = ""
        )
    }
    const handlePackingUpload = (e: any) => {
        getPackingToArray(e.target.files[0]).then(
            (packings) => {
                uploadMassivePackingFile(packings).then(
                    (res) => {
                        if (res) {
                            setSuccess(true)
                            closeAlert()
                        }
                        else {
                            setError("Error en servidor")
                            closeAlert()
                        }
                    }
                )
            }
        ).catch(
            (e) => {
                setError(e)
                closeAlert()
            }
        ).finally(
            () => e.target.value = ""
        )
    }


    return (
        <div className="h-full flex flex-col justify-center items-center gap-2">
            {success &&
                (<Alert
                    onClose={
                        () => setSuccess(false)
                    }
                    severity="success"
                >El archivo fue subido correctamente
                </Alert>)}
            {error !== "" &&
                (<Alert
                    onClose={
                        () => setError("")
                    }
                    severity="error"
                >{error}</Alert>)}

            <h1>Cargas Masivas</h1>
            <div>
                <input
                    accept=".xls, .xlsx"
                    style={{ display: "none" }}
                    id="providerorder-button-file"
                    type="file"
                    onChange={(e) => handleProviderOrderUpload(e)}
                />
                <label htmlFor="providerorder-button-file">
                    <Button
                        sx={{ ml: 2 }}
                        variant="contained"
                        color="secondary"
                        size="large"
                        component="span"
                    >
                        Cargar Ordenes Pendientes de Proveedores
                    </Button>
                </label>
            </div>
            <div>
                <input
                    accept=".xls, .xlsx"
                    style={{ display: "none" }}
                    id="customerorder-button-file"
                    type="file"
                    onChange={(e) => handleCustomerOrderUpload(e)}
                />
                <label htmlFor="customerorder-button-file">
                    <Button
                        sx={{ ml: 2 }}
                        variant="contained"
                        color="secondary"
                        size="large"
                        component="span"
                    >
                        Cargar Ordenes Pendientes a Clientes
                    </Button>
                </label>
            </div>
            <div>
                <input
                    accept=".xls, .xlsx"
                    style={{ display: "none" }}
                    id="providerleadtime-button-file"
                    type="file"
                    onChange={(e) => handleProvidersUpload(e)}
                />
                <label htmlFor="providerleadtime-button-file">
                    <Button
                        sx={{ ml: 2 }}
                        variant="contained"
                        color="secondary"
                        size="large"
                        component="span"
                    >
                        Cargar LeadTime Proveedores
                    </Button>
                </label>
            </div>
            <div>
                <input
                    accept=".xls, .xlsx"
                    style={{ display: "none" }}
                    id="product-button-file"
                    type="file"
                    onChange={(e) => handleProductsUpload(e)}
                />
                <label htmlFor="product-button-file">
                    <Button
                        sx={{ ml: 2 }}
                        variant="contained"
                        color="secondary"
                        size="large"
                        component="span"
                    >
                        Cargar Productos
                    </Button>
                </label>
            </div>
            <div>
                <input
                    accept=".xls, .xlsx"
                    style={{ display: "none" }}
                    id="packing-button-file"
                    type="file"
                    onChange={(e) => handlePackingUpload(e)}
                />
                <label htmlFor="packing-button-file">
                    <Button
                        sx={{ ml: 2 }}
                        variant="contained"
                        color="secondary"
                        size="large"
                        component="span"
                    >
                        Cargar Empaques
                    </Button>
                </label>
            </div>

        </div>
    )
}

export default UploadsView