import Excel from "exceljs"

export async function getPackingToArray(file) {
    const reader = new FileReader();
    const wb = new Excel.Workbook();
    reader.readAsArrayBuffer(file);
    return new Promise((resolve, reject) => {
        reader.onload = () => {
          const buffer = reader.result;
          let rowsToArray = [];
          wb.xlsx
            .load(buffer)
            .then((workbook) => {
                workbook.worksheets[0].eachRow((row, rowNumber) => {
                    let rowToArray = {};
                    if(rowNumber > 1) {     
                        if(row.values[1] === undefined || row.values[3] === undefined) {
                            reject('Error en la fila ' + rowNumber + ' del archivo')
                        }
                        else {
                        rowToArray['sku'] = row.values[1].toString()
                        const packing = row.values[3]
                        if(typeof packing === "number") {
                            rowToArray['packing'] = parseInt(packing)
                        }
                        else {
                            rowToArray['packing'] = null
                        }
                        
                    rowsToArray.push(rowToArray);
                    }
                }
                    
                })
            }).then(() => {
                resolve(rowsToArray);
              });

        };
      });
   
}