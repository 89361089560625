import axios from "./client"

const API_URL = process.env.REACT_APP_API_URL;

export const getLimits = async () => {
    try {
        const response = await axios.get(`${API_URL}limit`)
        return response.data.data.limit
    }
      catch(err) {
        alert(err)
        return false
      }
}

export const updateLimits = async (limits: Limit[]) => {
    try {
        await axios.post(`${API_URL}limit`, 
            limits
        )
        return true
    }
        catch(err) {
            alert(err)
            return false
        }
}