import Excel from "exceljs"

export async function getProvidersToArray(file) {
    const reader = new FileReader();
    const wb = new Excel.Workbook();
    reader.readAsArrayBuffer(file);
    return new Promise((resolve, reject) => {
        reader.onload = () => {
          const buffer = reader.result;
          let rowsToArray = [];
          wb.xlsx
            .load(buffer)
            .then((workbook) => {
                workbook.worksheets[0].eachRow((row, rowNumber) => {
                    let rowToArray = {};
                    if(rowNumber > 1) {
                        if(row.values[1] !== undefined && row.values[2] !== undefined) {
                        rowToArray['name'] = row.values[1].toString()
                        rowToArray['leadTime'] = parseInt(row.values[2])
                    rowsToArray.push(rowToArray);
                        }
                    else {
                        reject('Error en la fila ' + rowNumber + ' del archivo')
                    }
                    }
                    
                })
            }).then(() => {
                resolve(rowsToArray);
              });

        };
      });
   
}