import React from 'react';
import { FormControlLabel, Checkbox } from "@mui/material";

interface TrafficLightFiltersProps {
    trafficLightChecked: {
        green: boolean;
        yellow: boolean;
        red: boolean;
    };
    handleTrafficLightChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TrafficLightFilters: React.FC<TrafficLightFiltersProps> = ({ trafficLightChecked, handleTrafficLightChange }) => {
    return (
        <div className="flex justify-center w-full">
            <FormControlLabel
                control={<Checkbox checked={trafficLightChecked.green} onChange={handleTrafficLightChange} name="green" />}
                label="Verde"
            />
            <FormControlLabel
                control={<Checkbox checked={trafficLightChecked.yellow} onChange={handleTrafficLightChange} name="yellow" />}
                label="Amarillo"
            />
            <FormControlLabel
                control={<Checkbox checked={trafficLightChecked.red} onChange={handleTrafficLightChange} name="red" />}
                label="Rojo"
            />
        </div>
    );
};

export default TrafficLightFilters;