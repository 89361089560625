import Excel from "exceljs";

export async function getProviderOrdersToArray(file) {
    const reader = new FileReader();
    const wb = new Excel.Workbook();
    reader.readAsArrayBuffer(file);
    return new Promise((resolve, reject) => {
            reader.onload = () => {
              const buffer = reader.result;
              let rowsToArray = [];
              wb.xlsx
                .load(buffer)
                .then((workbook) => {
                  workbook.worksheets[0].eachRow((row, rowNumber) => {
                    let rowToArray = {};
                    if(rowNumber > 1) {
                        if(row.values[1] !== undefined && row.values[5] !== undefined && row.values[12] !== undefined) {
                        rowToArray['ocNumber'] = row.values[1].toString()
                        rowToArray['sku'] = row.values[5].toString()
                        if(parseInt(row.values[12]) < 0) {
                            rowToArray['pendingQuantity'] = 0
                        }
                        else {
                            rowToArray['pendingQuantity'] = parseInt(row.values[12])
                        }
                    rowsToArray.push(rowToArray);
                    }
                    else {
                      reject('Error en la fila ' + rowNumber + ' del archivo')
                    }
                  }
                  
                    
                })
            }).then(() => {
                resolve(rowsToArray);
              });

        };
      });
   
}