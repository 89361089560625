export const quantityToBuy = (productWithSales, temporality, expectedGrowth, includeReception) => {
    const selectedSales = productWithSales['selectedValue']

    let { stockTotal, totalPendingCustomerOrders, totalPendingProviderOrders, monthsToBuy, leadTime } = productWithSales
    if(!includeReception) {
        totalPendingProviderOrders = 0
    }
    const numberToReturn = ((parseFloat(monthsToBuy) + (parseInt(leadTime) / 30))* parseInt(selectedSales || "0")*(1+parseInt(temporality)/100)*(1+parseInt(expectedGrowth)/100)) - (parseInt(stockTotal) + parseInt(totalPendingProviderOrders || "0") - parseInt(totalPendingCustomerOrders || "0"))
    if(numberToReturn < 0) return 0
    return numberToReturn
}

export const getAdjustedQuantityToBuy = (quantityToBuy, packing) => {
    if(!packing) return Math.ceil(quantityToBuy)
    const packagesQty = Math.ceil(quantityToBuy / packing)
    return packagesQty * packing
}
