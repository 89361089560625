import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getMonthForTable } from "../../../utils/getMonthForTable";
import { getLimits } from "../../../services/limitService";


interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    averageMonths: string[];
    setAverageMonths: (months: string[]) => void;
    setTopLimit: (limit: number) => void;
    setBottomLimit: (limit: number) => void;
}
const CustomMonthSelectModal: React.FC<Props> = ({ open, onClose, averageMonths, setAverageMonths, setTopLimit, setBottomLimit }) => {
    const [customSelectedMonths, setCustomSelectedMonths] = useState<string[]>([]);
    var columnsList: string[] =
        [
            "month1",
            "month2",
            "month3",
            "month4",
            "month5",
            "month6",
            "month7",
            "month8",
            "month9",
            "month10",
            "month11",
            "month12",
            "month13",
            "month14",
            "month15",
            "month16",
            "month17",
            "month18",
        ];
    var columnNames: string[] = [
        getMonthForTable(1),
        getMonthForTable(2),
        getMonthForTable(3),
        getMonthForTable(4),
        getMonthForTable(5),
        getMonthForTable(6),
        getMonthForTable(7),
        getMonthForTable(8),
        getMonthForTable(9),
        getMonthForTable(10),
        getMonthForTable(11),
        getMonthForTable(12),
        getMonthForTable(13),
        getMonthForTable(14),
        getMonthForTable(15),
        getMonthForTable(16),
        getMonthForTable(17),
        getMonthForTable(18),
    ];

    const handleCheck = (event: { target: { checked: any; value: any } }) => {
        var updatedList = [...customSelectedMonths];
        if (event.target.checked) {
            updatedList = [...customSelectedMonths, event.target.value];
        } else {
            updatedList.splice(customSelectedMonths.indexOf(event.target.value), 1);
        }
        setCustomSelectedMonths(updatedList);
    };
    useEffect(() => {
        setCustomSelectedMonths(averageMonths)
    }, [open])
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Seleccion de Meses</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Elige los meses a promediar
                </DialogContentText>
                <FormControl>
                    {columnsList.map((column, index) => (
                        <FormControlLabel
                            key={column}
                            control={
                                <Checkbox
                                    value={column}
                                    onChange={handleCheck}
                                    checked={customSelectedMonths.includes(column)}
                                />
                            }
                            label={columnNames[index]}
                        />
                    ))}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={async () => {
                        await getLimits().then((limits) => {
                            if (limits) {
                                const topLimit = limits.find((limit: Limit) => limit.typeOfLimit === "top")?.limit!;
                                const bottomLimit = limits.find((limit: Limit) => limit.typeOfLimit === "bottom")?.limit!;
                                setTopLimit(topLimit);
                                setBottomLimit(bottomLimit);
                            }
                        });
                        setAverageMonths(customSelectedMonths);
                        onClose();
                    }}
                >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomMonthSelectModal;
