import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import createExcels from "../../../utils/createExcels"


interface CartItem {
    sku: string;
    description: string;
    adjustedQuantityToBuy: number;
    cost: number;
    provider: string;
}

interface CartModalProps {
    open: boolean;
    onClose: () => void;
    cart: CartItem[];
    setCart: (cart: CartItem[]) => void;
    saveCartToLocalStorage: (cart: CartItem[]) => void;
}

const CartModal: React.FC<CartModalProps> = ({ open, onClose, cart, setCart, saveCartToLocalStorage }) => {
    const generateOCAndEmptyCart = async () => {
        await createExcels.handleGenerateExcel(cart)

        // Vaciar el carrito
        setCart([]);
        saveCartToLocalStorage([]);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Carrito</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>SKU</TableCell>
                            <TableCell>Descripción</TableCell>
                            <TableCell>Cantidad Ajustada</TableCell>
                            <TableCell>Costo Unitario</TableCell>
                            <TableCell>Proveedor</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cart.map((item) => (
                            <TableRow key={item.sku}>
                                <TableCell>{item.sku}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{item.adjustedQuantityToBuy}</TableCell>
                                <TableCell>{item.cost}</TableCell>
                                <TableCell>{item.provider}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={generateOCAndEmptyCart}>Generar OC y Vaciar Carrito</Button>
                <Button onClick={onClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CartModal;
